<template>
  <div
    data-cy="j-site-onboarding__container"
    class="h-screen py-[47px] px-[141px] overflow-y-scroll"
  >
    <BaseButton
      data-cy="j-site-onboarding__back-button"
      type="button"
      class="border-0 text-green-700 text-sm mb-7"
      @click="onBack"
    >
      <template #icon-left>
        <JdsIcon
          name="arrow-left"
          size="16px"
          fill="#069550"
          class="h-4 w-4"
        />
      </template>
      <p>
        Kembali ke CMS Portal
      </p>
    </BaseButton>
    <OnboardingSlider />
    <div class="flex justify-center mt-7">
      <BaseButton
        data-cy="j-site-onboarding__button"
        type="button"
        class="bg-green-700 text-sm text-white"
        @click="onCreate"
      >
        <template #icon-left>
          <JdsIcon
            name="plus"
            size="16px"
            fill="#FFFFFF"
            class="h-4 w-4"
          />
        </template>
        <p>
          Buat J-Site
        </p>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/common/components/BaseButton';
import OnboardingSlider from '@/components/Microsite/Onboarding/Slider';

export default {
  components: {
    BaseButton,
    OnboardingSlider,
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },
    onCreate() {
      this.$router.push('/j-site/tambah');
    },
  },
};
</script>
