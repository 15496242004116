<template>
  <div class="flex">
    <swiper
      v-show="swiperReady"
      ref="facilitySwiper"
      :options="swiperOptions"
      data-cy="slider__container"
      class="w-full"
      @ready="swiperReady = true"
      @mouseover.native="pauseSwiper"
      @mouseleave.native="resumeSwiper"
    >
      <swiper-slide
        data-cy="slider__item"
        v-for="(image, index) in images"
        :key="index"
        class="flex flex-col text-center justify-center items-center pb-16"
      >
        <img
          data-cy="slider__image"
          :src="image.src"
          :alt="`Gambar Splash Screen ${index+1}`"
          height="300"
          class="mb-10 max-h-[300px]"
        >
        <!-- eslint-disable-next-line vue/valid-v-html -->
        <div
          data-cy="slider__title"
          class="font-inter font-bold text-[32px] leading-[50px] mb-[14px]"
          v-html="image.title"
        />
        <p
          data-cy="slider__paragraph"
          class="font-roboto text-sm text-gray-700 leading-[23px] max-w-[746px]"
        >
          {{ image.paragraph }}
        </p>
      </swiper-slide>

      <!-- Pagination Bullet -->
      <div
        slot="pagination"
        class="swiper-pagination"
      />
    </swiper>
  </div>
</template>

<script>

export default {
  data() {
    return {
      images: [
        {
          id: 1,
          src: require('@/assets/images/splash-screen-1.svg'),
          title: '<h1>Membuat Website <span class="text-green-700">Mudah</span> Dan <span class="text-green-700">Cepat</span></h1>',
          paragraph: 'Buat situs web profesional tanpa perlu keahlian teknis atau pemrograman dengan mudah menggunakan pembuat situs web kami dalam waktu singkat dengan fitur kustomisasi element widget yang intuitif dan mudah digunakan.',
        },
        {
          id: 2,
          src: require('@/assets/images/splash-screen-2.svg'),
          title: '<h1><span class="text-green-700">Responsif</span> Dan <span class="text-green-700">Mobile-Friendly</span></h1>',
          paragraph: 'Situs web Anda akan terlihat hebat di perangkat apa pun dengan desain responsif yang otomatis menyesuaikan tampilan situs Anda dengan ukuran layar pengguna.',
        },
        {
          id: 3,
          src: require('@/assets/images/splash-screen-3.svg'),
          title: '<h1>Hemat Biaya</h1>',
          paragraph: 'Buat situs web profesional dengan harga yang terjangkau tanpa perlu membayar biaya tambahan untuk hosting, domain, dan pembaruan.',
        },
      ],
      swiperReady: false,
      swiperOptions: Object.freeze({
        slidesPerView: 1,
        speed: 750,
        mousewheel: true,
        passiveListeners: true,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      }),
    };
  },
  computed: {
    swiper() {
      return this.$refs.facilitySwiper.$swiper;
    },
  },
  methods: {
    pauseSwiper() {
      if (this.swiper) {
        this.swiper.autoplay.stop();
      }
    },
    resumeSwiper() {
      if (this.swiper) {
        this.swiper.autoplay.start();
      }
    },
  },
};
</script>

<style lang="scss">
  .swiper-pagination-bullet {
    width: 8px !important;
    height: 8px !important;
    border-radius: 9999px !important;
    border-color: #069550 !important;
    border-width: 2px;
    margin-right: 8px !important;
    background: transparent !important;
    opacity: 1 !important;

    &-active {
      background: #069550 !important;
      transition: width 0.3s ease-out;
    }
  }
</style>
